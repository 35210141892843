import React from 'react';
import { RichText } from 'prismic-reactjs';

import css from './privacy-policy.module.scss';
import shared from 'styles/shared.module.scss';

const TermsConditions = ({ data: { prismic } }) => {
  const content = prismic.allPrivacys.edges[0];
  if (!content) return null;

  const privacy = content.node;

  return (
    <>
      <section className={css.host}>
        <div className={`${css.container} ${shared.maxwidth}`}>
          <h1 className={shared.sectionTitle}>{privacy.title}</h1>
          <div className={css.content}>
            <RichText render={privacy.content} />
          </div>
        </div>
      </section>
    </>
  );
};

export default TermsConditions;
export const pageQuery = graphql`
  query Privacy {
    prismic {
      allPrivacys {
        edges {
          node {
            title
            content
          }
        }
      }
    }
  }
`;
